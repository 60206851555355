<template>
  <div class="procureMonthly">
    <h1>采购月明细</h1>
    <el-form :inline="true">
      <el-form-item label="采购月份">
        <el-date-picker :clearable="false" v-model="searchParam.month" type="month" style="width: 110px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="月目标值">
        <el-input-number :controls="false" :precision="2" v-model="data.procureMonthly.targetProductQuantity" style="max-width: 400px;" />
        <el-button @click="updateTargetProductQuantity()">修改</el-button>
      </el-form-item>
    </el-form>
    <div style="display: flex;flex-wrap: wrap;">
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>完成平均车数：{{ math.formatNumber(math.halfUp(data.avgTruckQuantity, 0)) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>平均吨数：{{ math.formatNumber(data.avgSendQuantity) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>已完成吨数：{{ math.formatNumber(data.overSendQuantity) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>未完成吨数：{{ math.formatNumber(data.unSendQuantity) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>后均发运吨数：{{ math.formatNumber(data.backAvgSendQuantity) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>后均发运车数：{{ math.formatNumber(math.halfUp(data.backAvgTruckQuantity, 0)) }}</el-text>
      </div>
      <div style="margin: 0px 10px 5px 10px;">
        <el-text>全年总计发运量：{{ math.formatNumber(data.sumSendQuantityForYear) }}</el-text>
      </div>
    </div>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border height="calc(100vh - 310px)">
      <el-table-column prop="sendDate" label="采购日期" width="100" show-overflow-tooltip />
      <el-table-column prop="truckQuantity" label="车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ scope.row.truckQuantity }}
        </template>
      </el-table-column>
      <el-table-column prop="sendQuantity" label="采购数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="rollSumSendQuantity" label="本月累计数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.rollSumSendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="rollLessSendQuantity" label="未完成目标数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.rollLessSendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="rollSumSendQuantityForYear" label="全年累计数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.rollSumSendQuantityForYear) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const tabRef = ref()

const data = reactive({
  list: [],
  procureMonthly: {},
  avgTruckQuantity: 0,
  avgSendQuantity: 0,
  overSendQuantity: 0,
  unSendQuantity: 0,
  backAvgSendQuantity: 0,
  backAvgTruckQuantity: 0,
  sumSendQuantityForYear: 0,
})

const show = reactive({
  selected: [],
})
const now = new Date()
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.month = now
}
resetSearchParam()

const commitFindList = () => {
  const params = JSON.parse(JSON.stringify(searchParam))
  if (searchParam.month) {
    params.month = util.parseTime(searchParam.month, '{y}-{m}-{d}')
  }
  api.get('/backend/procureMonthly/getMonthly', { params: params }).then(res => {
    data.list = res.list
    data.procureMonthly = res.procureMonthly || {}
    let truckQuantity = 0
    let sendQuantity = 0
    let row = 0
    let unRow = 0
    for (const i in data.list) {
      const item = data.list[i]
      console.log(item.sendDate)
      if (new Date(item.sendDate) < now) {
        row++
        truckQuantity = math.plus(item.truckQuantity, truckQuantity)
        sendQuantity = math.plus(item.sendQuantity, sendQuantity)
      } else {
        unRow++
      }
    }
    const last = data.list[data.list.length - 1]
    if (row != 0) {
      data.avgTruckQuantity = math.halfUp(truckQuantity / row, 2)
      data.avgSendQuantity = math.halfUp(sendQuantity / row, 2)
    } else {
      data.avgTruckQuantity = 0
      data.avgSendQuantity = 0
    }
    data.overSendQuantity = last.rollSumSendQuantity
    data.unSendQuantity = last.rollLessSendQuantity
    data.sumSendQuantityForYear = res.sumSendQuantityForYear
    if (unRow != 0) {
      data.backAvgSendQuantity = math.halfUp(last.rollLessSendQuantity / unRow, 2)
    } else {
      data.backAvgSendQuantity = 0
    }
    if (sendQuantity != 0) {
      data.backAvgTruckQuantity = math.halfUp(last.rollLessSendQuantity * truckQuantity / sendQuantity, 2)
    } else {
      data.backAvgTruckQuantity = 0
    }
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const updateTargetProductQuantity = () => {
  const param = {}
  if (!data.procureMonthly.targetProductQuantity) {
    ElMessage.error('请先填写目标值再点修改')
    return
  }
  data.procureMonthly.month = util.parseTime(searchParam.month, '{y}-{m}-{d}')
  param.id = data.procureMonthly.id
  param.targetProductQuantity = data.procureMonthly.targetProductQuantity
  param.month = data.procureMonthly.month
  ElMessageBox.confirm('', {
    title: '确认修改月目标值',
    message: '将' + util.parseTime(param.month, '{y}年{m}月') + '的目标值设置为' + param.targetProductQuantity,
    autofocus: false,
    confirmButtonText: '确定',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/procureMonthly/set', param).then(() => {
      ElMessage.success('设置成功')
      commitFindList()
    })
  })
}
</script>

<style lang="less"></style>